globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"jrwYGyFL6LOGqNKeOl1CM"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

let errorTimeoutId: NodeJS.Timeout | null = null;

const clearErrorTimeout = () => {
  if (errorTimeoutId) {
    clearTimeout(errorTimeoutId);
    errorTimeoutId = null;
  }
};

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://288f9ec7b99117ae2f95ac00e48a183f@o4505843707674624.ingest.us.sentry.io/4508160646840320",
    tracesSampleRate: 1,
    debug: false,

    beforeSend(event) {
      if (event.exception && event.event_id) {
        if (!errorTimeoutId) {
          // Clear any existing timeout first
          clearErrorTimeout();

          // Sentry.showReportDialog({
          //   title: "Report an Issue",
          //   subtitle: "Please provide any additional information about the error.",
          //   eventId: event.event_id,
          // });

          errorTimeoutId = setTimeout(() => {
            clearErrorTimeout();
          }, 5000);

          // Cleanup on page unload
          window.addEventListener("unload", clearErrorTimeout);
        }
      }
      return event;
    },
  });
}
